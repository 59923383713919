import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Link, navigate } from 'gatsby';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserRoleContext } from '../../context';
import { ApolloContext } from '../../context/Apollo';
import useCompanyCheck from '../../hooks/useCompanyCheck';
import Logo from '../../images/header-logo.svg';
import ProfileIconWhite from '../../images/profile-icon-white.svg';
import ProfileIconGreen from '../../images/profile-icon.svg';
import { AlertIcon } from '../common';

const Menu = styled.div`
  backdrop-filter: blur(5px);

  @media only screen and (max-width: 1022px) {
    display: none;
  }
  @media only screen and (min-width: 1023px) {
    box-shadow: none !important;
  }
  @media only screen and (min-width: 1023px) {
    display: flex !important;
    max-width: ${(props) => (props.isAdmin ? '520px' : '450px')};
  }
`;

const CompanyMenu = styled.div`
  backdrop-filter: blur(5px);

  @media only screen and (max-width: 1022px) {
    display: none;
  }
  @media only screen and (min-width: 1023px) {
    box-shadow: none !important;
  }
  @media only screen and (min-width: 1023px) {
    display: flex !important;
  }
`;

const MobileMenuIcon = ({ menuState }) => {
  const [showMobileMenu, toggleMobileMenu] = menuState;
  return (
    <div className="ml-md block lg:hidden cursor-pointer" onClick={() => toggleMobileMenu(!showMobileMenu)}>
      {showMobileMenu ? (
        <>
          <div className="bg-darkblue" style={{ width: 22, height: 3, transform: 'translateY(2px) rotate(45deg)' }} />
          <div className="bg-darkblue" style={{ width: 22, height: 3, transform: 'translateY(-1px) rotate(-45deg)' }} />
        </>
      ) : (
        <>
          <div className="bg-darkblue" style={{ width: 22, height: 3 }} />
          <div className="bg-darkblue mt-sm" style={{ width: 22, height: 3, marginTop: 4 }} />
          <div className="bg-darkblue mt-sm" style={{ width: 22, height: 3, marginTop: 4 }} />
        </>
      )}
    </div>
  );
};

const RECRUITER_NOTIFICATIONS = gql`
  subscription recruiterNotifications($recruiter_id: String) {
    notification(where: { recruiter_id: { _eq: $recruiter_id } }) {
      created_at
      candidate {
        id
        profilePictureURL
        name
      }
      type
      read
    }
  }
`;

const GET_COMPANY_ID = gql`
  subscription getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

const GET_SETTINGS = gql`
  query getSettings {
    settings {
      credit_rate
      daily_candidate_request_limit
      monthly_candidate_request_limit
      weekly_candidate_request_limit
      id
    }
  }
`;

const GET_REQUESTS_USED = gql`
  subscription getDailyRequestsUsed($companyId: uuid, $startDate: String, $endDate: String) {
    company_request_history_aggregate(where: { company_id: { _eq: $companyId }, created_at: { _gte: $startDate, _lte: $endDate } }) {
      aggregate {
        count
      }
    }
  }
`;

const RegularHeader = ({ props }) => {
  const { showMobileMenu, nav, toggleMobileMenu, notifications, path, unread, isProfileModalVisible, userRoleState, currentUser } = props;

  const [userRole] = userRoleState;
  const hiddenAdminRoutes = ['recruiters'];
  const isAdmin = userRole === 'admin';

  return (
    <div className="flex items-center">
      {!isAdmin && (
        <>
          <Menu
            isAdmin={isAdmin}
            className="mr-sm absolute shadow flex-col border-t lg:border-t-0 lg:bg-white lg:flex-row lg:static left-0"
            style={{ backgroundColor: 'rgba(255,255,255,0.3)', width: '100vw', top: 80, zIndex: 1000000, display: showMobileMenu ? 'flex' : 'none' }}
          >
            {nav.map((link, index) => (
              <div
                key={index}
                className={`justify-center ${(!isAdmin && hiddenAdminRoutes.includes(link)) || (isAdmin && link === 'chat') ? 'hidden' : 'flex'}`}
                style={{ flexGrow: 1 }}
              >
                <Link
                  to={`/${link === 'loxo-candidates' ? 'candidate-imports/loxo' : link}`}
                  onClick={() => toggleMobileMenu(false)}
                  className={`text-left capitalize text-darkgray py-md lg:py-0 border-b lg:border-0 w-full lg:w-auto text-center`}
                  activeStyle={{ color: '#222e42' }}
                  activeClassName="nav-active"
                  partiallyActive={true}
                  style={{ fontWeight: 600, fontSize: 16, transition: 'color 0.2s ease-in-out' }}
                >
                  {link === 'loxo-candidates' ? 'Import Candidates' : link}
                </Link>
              </div>
            ))}
          </Menu>
          <Link style={{ position: 'relative' }} to="/notifications" state={{ notifications }}>
            <AlertIcon active={path === '/notifications' ? true : false} />
            {unread && (
              <div className="bg-red" style={{ height: 9.5, width: 9.5, borderRadius: 15, right: 17, top: 0, borderWidth: 2, borderColor: 'white', position: 'absolute' }} />
            )}
          </Link>
        </>
      )}
      {/* <div onClick={() => toggleProfileModal(!isProfileModalVisible)}>
        <img
          src={isProfileModalVisible ? ProfileIconGreen : ProfileIconWhite}
          style={{ height: 30, margin: 0, cursor: "pointer" }}
        />
      </div> */}
      <div onClick={() => navigate('/settings')}>
        <img alt="" src={isProfileModalVisible ? ProfileIconGreen : ProfileIconWhite} style={{ height: 30, margin: 0, cursor: 'pointer' }} title={currentUser.email} />
      </div>
      {isAdmin && <MobileMenuIcon menuState={[showMobileMenu, toggleMobileMenu]} />}
    </div>
  );
};

const CompanyHeader = ({ props }) => {
  const { showMobileMenu, nav, toggleMobileMenu, isProfileModalVisible, currentUser } = props;

  const [availableCredit, setAvailableCredit] = useState();
  const [companyId, setCompanyId] = useState('');
  const [availableRequestLimit, setAvailableRequestLimit] = useState('0');
  const { apolloClient } = useContext(ApolloContext);

  const { data } = useSubscription(GET_COMPANY_ID, {
    variables: {
      admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });

  const { data: requestsUsedData } = useSubscription(GET_REQUESTS_USED, {
    variables: {
      companyId: companyId,
      startDate: moment()
        .startOf('day')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .endOf('day')
        .format('YYYY-MM-DD'),
    },
  });

  async function updateRequestCount() {
    const response = await apolloClient.query({
      query: GET_SETTINGS,
      variables: {},
      fetchPolicy: 'network-only',
    });

    const settings = response.data.settings[0];

    const dailyRequestSent = requestsUsedData.company_request_history_aggregate.aggregate.count;

    setAvailableRequestLimit(settings.daily_candidate_request_limit - dailyRequestSent);
  }

  useEffect(() => {
    if (data && requestsUsedData && companyId) {
      if (data.company[0].subscription_type === 'PAID') {
        setAvailableRequestLimit('Unlimited');
      } else {
        updateRequestCount();
      }
    }
  }, [data, requestsUsedData, companyId]);

  useEffect(() => {
    if (data) {
      setAvailableCredit(data.company[0].remainingCredit);

      setCompanyId(data.company[0].id);
    }
  }, [data]);

  return (
    <div className="flex items-center">
      <CompanyMenu className="mr-sm absolute flex-col lg:flex-row lg:static left-0 bg-white" style={{ top: 80, zIndex: 10000, display: showMobileMenu ? 'flex' : 'none' }}>
        <div className="flex justify-center">
          <Link
            to={`/checkout`}
            className={`text-left text-darkgray py-sm lg:py-0`}
            activeStyle={{ color: '#222e42' }}
            activeClassName="nav-active"
            style={{ fontWeight: 600, fontSize: 16, transition: 'color 0.2s ease-in-out' }}
          >
            Daily Requests Available: {availableRequestLimit}
          </Link>
        </div>
        <div className="px-lg flex justify-center">
          <Link
            to={`/checkout`}
            className={`text-left capitalize text-darkgray py-sm lg:py-0`}
            activeStyle={{ color: '#222e42' }}
            activeClassName="nav-active"
            style={{ fontWeight: 600, fontSize: 16, transition: 'color 0.2s ease-in-out' }}
          >
            Credits: {availableRequestLimit !== '0' && availableCredit !== '' ? (availableRequestLimit === 'Unlimited' ? 'Unlimited' : availableCredit) : '-'}
          </Link>
        </div>
        {nav.map((link, index) => (
          <div key={index} className="px-lg flex justify-center">
            <Link
              to={`/${link}`}
              onClick={() => toggleMobileMenu(false)}
              className={`text-left capitalize text-darkgray py-sm lg:py-0`}
              activeStyle={{ color: '#222e42' }}
              activeClassName="nav-active"
              partiallyActive={true}
              style={{ fontWeight: 600, fontSize: 16, transition: 'color 0.2s ease-in-out' }}
            >
              {link === 'saved-candidates' ? 'Unlocked Candidates' : link === 'favorite-candidates' ? 'Favorites' : link === 'chat' ? 'Chat' : 'Dashboard'}
            </Link>
          </div>
        ))}
      </CompanyMenu>
      {/* <div onClick={() => toggleProfileModal(!isProfileModalVisible)}>
        <img
          src={isProfileModalVisible ? ProfileIconGreen : ProfileIconWhite}
          style={{ height: 30, margin: 0, cursor: "pointer" }}
        />
      </div> */}
      <div onClick={() => navigate('/settings')}>
        <img alt="" src={isProfileModalVisible ? ProfileIconGreen : ProfileIconWhite} style={{ height: 30, margin: 0, cursor: 'pointer' }} title={currentUser.email} />
      </div>
      <MobileMenuIcon menuState={[showMobileMenu, toggleMobileMenu]} />
    </div>
  );
};

const AdminHeader = ({ props }) => {
  const { showMobileMenu, nav, toggleMobileMenu, isProfileModalVisible, currentUser } = props;

  return (
    <div className="flex items-center">
      <>
        <Menu
          className="mr-sm absolute shadow flex-col border-t lg:border-t-0 lg:bg-white lg:flex-row lg:static left-0"
          style={{ backgroundColor: 'rgba(255,255,255,0.3)', width: '10vw', top: 80, zIndex: 1000000, display: showMobileMenu ? 'flex' : 'none' }}
        >
          {nav.map((link, index) => (
            <div key={index} className={`justify-center flex flex-1`} style={{ flexGrow: 1 }}>
              <Link
                to={`/${link}`}
                onClick={() => toggleMobileMenu(false)}
                className={`text-left capitalize text-darkgray py-md lg:py-0 border-b lg:border-0 w-full lg:w-auto text-center`}
                activeStyle={{ color: '#222e42' }}
                activeClassName="nav-active"
                partiallyActive={true}
                style={{ fontWeight: 600, fontSize: 16, transition: 'color 0.2s ease-in-out' }}
              >
                {link}
              </Link>
            </div>
          ))}
        </Menu>
      </>
      <div onClick={() => navigate('/settings')}>
        <img alt="" src={isProfileModalVisible ? ProfileIconGreen : ProfileIconWhite} style={{ height: 30, margin: 0, cursor: 'pointer' }} title={currentUser.email} />
      </div>
      <MobileMenuIcon menuState={[showMobileMenu, toggleMobileMenu]} />
    </div>
  );
};

const AgencyHeader = ({ props }) => {
  const { showMobileMenu, nav, toggleMobileMenu, isProfileModalVisible, currentUser } = props;

  return (
    <div className="flex items-center">
      <>
        <Menu
          className="mr-sm absolute shadow flex-col border-t lg:border-t-0 lg:bg-white lg:flex-row lg:static left-0"
          style={{ backgroundColor: 'rgba(255,255,255,0.3)', width: '10vw', top: 80, zIndex: 1000000, display: showMobileMenu ? 'flex' : 'none' }}
        >
          {nav.map((link, index) => (
            <div key={index} className={`justify-center flex flex-1`} style={{ flexGrow: 1 }}>
              <Link
                to={`/${link}`}
                onClick={() => toggleMobileMenu(false)}
                className={`text-left capitalize text-darkgray py-md lg:py-0 border-b lg:border-0 w-full lg:w-auto text-center`}
                activeStyle={{ color: '#222e42' }}
                activeClassName="nav-active"
                partiallyActive={true}
                style={{ fontWeight: 600, fontSize: 16, transition: 'color 0.2s ease-in-out' }}
              >
                {link}
              </Link>
            </div>
          ))}
        </Menu>
      </>
      <div onClick={() => navigate('/settings')}>
        <img alt="" src={isProfileModalVisible ? ProfileIconGreen : ProfileIconWhite} style={{ height: 30, margin: 0, cursor: 'pointer' }} title={currentUser.email} />
      </div>
      <MobileMenuIcon menuState={[showMobileMenu, toggleMobileMenu]} />
    </div>
  );
};

export default function Header({ profileModalState, path, userRoleState, currentUser }) {
  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';
  const isRecruiter = userRole === 'recruiter';
  const isSubscriber = userRole === 'company';
  const isAgency = userRole === 'agency';

  const { loading } = useCompanyCheck();
  // const companyUser = !loading && isCompany;
  // const regularUser = !loading && !isCompany;

  // const regularNav = ["dashboard", "jobs", "candidates", "recruiters", "chat"];
  const regularNav = ['dashboard', 'jobs', 'loxo-candidates', 'recruiters', 'chat'];
  const companyNav = ['dashboard/find-candidate', 'saved-candidates', 'favorite-candidates', 'chat'];
  const adminNav = ['dashboard'];
  const agencyNav = ['dashboard'];
  const nav = isAgency ? agencyNav : !isAdmin ? (isRecruiter ? regularNav : companyNav) : adminNav;

  const [isProfileModalVisible, toggleProfileModal] = profileModalState;

  const { data } = useSubscription(RECRUITER_NOTIFICATIONS, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });
  const [showMobileMenu, toggleMobileMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(false);

  useEffect(() => {
    if (data && isRecruiter) {
      setNotifications(data.notification);
    }
  }, [data, isRecruiter, loading]);

  useEffect(() => {
    if (isRecruiter) {
      const unreadNotificationCount = notifications.filter((a) => !a.read).length;

      setUnread(unreadNotificationCount > 0);
    }
  }, [notifications, isRecruiter, loading]);

  const recruiterUserProps = { showMobileMenu, nav, toggleMobileMenu, path, unread, toggleProfileModal, isProfileModalVisible, notifications, userRoleState, currentUser };

  const subscriberUserProps = { showMobileMenu, nav, toggleMobileMenu, toggleProfileModal, isProfileModalVisible, currentUser };
  const adminUserProps = { showMobileMenu, nav, toggleMobileMenu, toggleProfileModal, isProfileModalVisible, currentUser };
  const agencyUserProps = { showMobileMenu, nav, toggleMobileMenu, toggleProfileModal, isProfileModalVisible, currentUser };

  const roleName = isAgency ? 'agency' : isRecruiter ? 'Recruiter' : (isAdmin ? 'Admin' : 'Hiring Marketplace');
  return (
    <header className="w-full shadow bg-white fixed flex justify-center px-md font-main shadow" style={{ minHeight: 80, height: 80, zIndex: 1000 }}>
      <div className="container flex items-center justify-between h-full left">
        <div style={{ width: 200, margin: 0, display: 'flex', flexDirection: 'column' }}>
          <img onClick={() => navigate('/dashboard/')} className="cursor-pointer" alt="" src={Logo} style={{ maxWidth: 200, margin: 0, width: '100%' }} />
          <span style={{ fontSize: '.45rem', fontWeight: 'bold', bottom: '0', alignSelf: 'flex-end', marginTop: '-1rem' }}>{roleName}</span>
        </div>
        {isRecruiter && <RegularHeader props={recruiterUserProps} />}
        {isSubscriber && <CompanyHeader props={subscriberUserProps} />}
        {isAdmin && <AdminHeader props={adminUserProps} />}
        {isAgency && <AgencyHeader props={agencyUserProps} />}
      </div>
    </header>
  );
}
